
@import  "variables";
@import  "bootstrap/scss/bootstrap.scss";


$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
}

.social-icon {
	width: 30px;
	height: 30px;
	margin-right: 10px;
	margin-left: 10px;
}