/* Define theme cols */
$antique-ruby: #88292fff;

$pine-tree: #2b2d24ff;
$dark-jungle-green: #1e2019ff;

$light-gray: #dbd4d3ff;

$queen-blue: #41658aff;
$russian-violet: #2f004fff;
$xiketic: #090b1fff;

/* Override bootstrap vars */
$primary: $antique-ruby;
$secondary: $pine-tree;
$dark: $dark-jungle-green;
$white: $light-gray;
